import React, { useEffect, useState } from "react";
import { PiUsersDuotone } from "react-icons/pi";
import Load from "../../../assets/rff.svg";
import Nothing from "../../../assets/svg/notClient.svg";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { RiUserStarLine } from "react-icons/ri";
import { FcCancel } from "react-icons/fc";
import { FcInfo } from "react-icons/fc";
import { AiTwotonePhone } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import { MdOutlineInfo } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiSearch } from "react-icons/ci";
import Config from "../../config/Links";
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";

// Product Detail ICo
import { FiPackage } from "react-icons/fi";
import { FaArrowDownShortWide } from "react-icons/fa6";
import { MdOutlineSell } from "react-icons/md";
import { RiMoneyEuroCircleLine } from "react-icons/ri";
import { RiProgress1Line } from "react-icons/ri";
import { IoTimeOutline } from "react-icons/io5";
import { BiMoney } from "react-icons/bi";
import { TbStatusChange } from "react-icons/tb";
import { IoCalendarNumberOutline } from "react-icons/io5";

export default function ClientsListLgg() {
  moment.locale("fr");
  const [nameSearch, setNameSearch] = useState("");
  const [clientsList, setClientsList] = useState([]);
  const [carnetHistoryList, setCarnetHistoryList] = useState([]);
  const [clientID, setClientID] = useState("");
  const [productID, setProductID] = useState("");
  const [clientDetail, setClientDetail] = useState({});
  const [clientProductsList, setClientProductsList] = useState([]);
  const [productDetail, setProductDetail] = useState("");
  const [getCol, setGetCol] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [notFound1, setNotFound1] = useState(false);
  const [notFound2, setNotFound2] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [colFilter, setColfilter] = useState("");

  // Fetch ALL vendor
  useEffect(() => {
    const fetchAllVendors = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/lgg/col/list?query=`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setGetCol(response.data.collecteurs);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAllVendors();
  }, []);

  // Fetch ALL Client
  useEffect(() => {
    const fetchAllClients = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/lgg/admin/clients?query=${nameSearch}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setLoading(false);
          setNotFound(false);
          setClientsList(response.data.data);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setLoading(false);
            setNotFound(true);
          }
          console.log(error);
        });
    };
    fetchAllClients();
  }, [nameSearch]);

  useEffect(() => {
    fetchClientDetail();
  }, [clientID]);

  // Fetch ALL Client Detail
  const fetchClientDetail = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/lgg/info/${clientID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setLoading1(false);
        setNotFound1(false);
        setClientDetail(response.data.client_info);
        setClientProductsList(response.data.client_products);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setLoading1(false);
          setNotFound1(true);
        }
        console.log(error);
      });
  };

  // Open Product Detail
  const openProductInfo = async (item) => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/open/client/product/info/${item}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setProductDetail(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Confirm order
  const confirmOrder = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/confirm/delivery/${productID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          setModal3(false);
          setModal1(false);
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Get Carnet History
  const getCarnetHistory = async (item) => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/product/history/${item}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCarnetHistoryList(response.data.historique);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="py-[60px] w-full flex-col  max-sm:px-2">
        {/* Header */}
        <div className="w-full mb-2 flex flex-row items-center justify-end px-3 max-sm:px-0">
          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recheche des clients"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />
          </div>
        </div>

        {/* Body */}
        <div>
          {loading ? (
            <div className="flex flex-col h-[80vh] items-center justify-center">
              <img src={Load} className="w-12 h-12" alt="Loading" />
            </div>
          ) : notFound ? (
            <div className="flex flex-col items-center justify-center">
              <img src={Nothing} className="w-32 h-32" alt="Not found" />
              <p className="text-white"> Client introuvable !</p>
            </div>
          ) : (
            <div className="w-full h-[auto] overflow-auto scrollbar-hide flex flex-wrap">
              {clientsList.map((item, index) => {
                const collecteur = getCol.filter(
                  (cc) => cc.id.toString() === item.collecteur_id
                );
                return (
                  <button
                    onClick={() => {
                      setModal(true);
                      fetchClientDetail();
                      setClientID(item.id);
                    }}
                    key={index}
                    className="bg-[#ffffff24] hover:bg-blue-600 w-[19%] flex items-start m-2 justify-start my-1.5 p-5 rounded"
                  >
                    <PiUsersDuotone size={50} color="white" />

                    <div className="flex flex-col items-start ml-3">
                      <div className="flex items-center mb-1">
                        <p className="text-white font-bold"> {item.nom}</p>
                        <p className="text-white font-semibold ml-2">
                          {item.prenom.length > 10
                            ? item.prenom.slice(0, 10) + "..."
                            : item.prenom}
                        </p>
                      </div>

                      {/* Phone */}
                      <div className="flex flex-row  ">
                        <AiTwotonePhone size={25} color="white" />
                        <p className="text-white ml-3"> {item.telephone}</p>
                      </div>

                      {/* Quarter */}
                      <div className="flex flex-row my-2 ">
                        <HiOutlineLocationMarker size={25} color="white" />
                        <p className="text-white  ml-3">{item.quartier}</p>
                      </div>

                      {/* Craetr date */}
                      <div className="flex flex-row  ">
                        <MdOutlineCalendarMonth size={25} color="white" />
                        <p className="text-white  ml-3">
                          {moment(item.created_at).calendar()}
                        </p>
                      </div>

                      {/* Col Name */}
                      <div className="flex flex-row mt-2">
                        <RiUserStarLine size={25} color="green" />
                        <p className="text-green-500  ml-3">
                          {collecteur?.nom || "Nom indisponible"}
                        </p>
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* Client Details Modals */}
      <div
        className={
          modal
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-5/12 top-[10%] h-[60%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              {/* Header */}
              <div className="flex flex-row justify-center items-center">
                <p className="text-white text-lg font-semibold text-center">
                  Details Clients
                </p>

                <p className="text-green-500 text-lg ml-2 font-semibold text-center">
                  {clientDetail.nom + " " + clientDetail.prenom}
                </p>
              </div>

              {/* Body */}
              {loading ? (
                <div className="flex flex-col items-center justify-center">
                  <img src={Load} className="w-12 h-12" />
                </div>
              ) : notFound ? (
                <div className="flex flex-col items-center justify-center">
                  <img src={Nothing} className="w-32 h-32" />
                  <p className="text-white">Aucun info trouvé !</p>
                </div>
              ) : (
                <div className="overflow-auto scrollbar-hide h-[85vh] p-1 min-h-[200px] px-1 mt-1">
                  <div className="">
                    {/* All Client info */}
                    <div className="bg-[#ffffff24] rounded-xl w-full p-2">
                      <p className="text-white text-base">
                        Nom & Prénom :{" "}
                        {clientDetail.nom + " " + clientDetail.prenom}
                      </p>

                      <p className="text-white text-base">
                        Téléphone : (228) {clientDetail.telephone}
                      </p>

                      <p className="text-white text-base">
                        Deuxieme téléphone : (228){" "}
                        {clientDetail.deuxieme_telephone ||
                          "Pas de deuxieme téléphone"}
                      </p>

                      <p className="text-white text-base">
                        quartier : {clientDetail.quartier}
                      </p>

                      <p className="text-white text-base">
                        Date : {moment(clientDetail.created_at).calendar()}
                      </p>
                    </div>
                  </div>

                  {/* Product List */}
                  <div className="w-full">
                    <p className="text-white font-semibold ml-2">
                      Liste des Carnets
                    </p>
                  </div>

                  {loading1 && clientProductsList.length === undefined ? (
                    <div className="flex flex-col h-[80vh] items-center justify-center">
                      <img src={Load} className="w-12 h-12" alt="Loading" />
                    </div>
                  ) : notFound1 ? (
                    <div className="flex flex-col items-center justify-center">
                      {/* <img
                        src={Nothing}
                        className="w-32 h-32"
                        alt="Not found"
                      /> */}

                      <img src={Load} className="w-12 h-12" alt="Loading" />
                      {/* <p className="text-white"> Liste des vide !</p> */}
                    </div>
                  ) : (
                    <div className="overflow-auto scrollbar-hide h-[auto] p-1 px-1">
                      {clientProductsList.map((item, index) => (
                        <button
                          onClick={() => {
                            setModal1(true);
                            setProductID(item.id);
                            openProductInfo(item.id);
                          }}
                          key={index}
                          className="bg-[#ffffff24] rounded-xl p-2 m-2"
                        >
                          <p className="text-white text-base">
                            Ref : {item.product_ref}
                          </p>

                          <p className="text-white text-base">
                            {item.product_name}
                          </p>

                          <p className="text-green-500 text-base">
                            {(
                              parseFloat(item.prix_vente).toFixed(0) * 1
                            ).toLocaleString("fr-FR") + " FCFA"}
                          </p>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Product Details */}
      <div
        className={
          modal1
            ? "w-[84%] h-[100vh] bg-[#38373780] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-4/12 top-[15%] h-[70%] bg-cyan-950 rounded-xl p-4">
          <button
            onClick={() => {
              setModal2(true);
            }}
            className="bg-gray-100 hover:bg-[#ffffff24] p-2 absolute left-1 top-1 rounded-full flex items-center justify-center"
          >
            <FcCancel />
            <p>Annuler</p>
          </button>

          <button
            onClick={() => setModal1(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Cotisation info */}
          <button
            onClick={() => {
              setModal4(true);
              getCarnetHistory(productDetail.id);
            }}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-12 rounded-full flex items-center justify-center"
          >
            <MdOutlineInfo />
          </button>

          {/* Header ICON */}
          <div className="flex w-full flex flex-col items-center justify-center">
            <BsCart4 size={100} color="white" />

            {/* Title */}
            <div className="bg-[#ffffff24] rounded-lg p-2 mt-2">
              <p className="text-white font-bold text-xl">
                {productDetail.product_name}
              </p>
            </div>
          </div>

          {/* Body product info */}
          <div className="h-[68%] flex flex-col justify-between my-0.5">
            {/* Nom */}
            <div className="flex items-center">
              <FiPackage size={25} color="white" />

              <p className="text-white font-bold text-lg ml-3">
                Nom : {productDetail.product_name}
              </p>
            </div>

            {/* Identifiant */}
            <div className="flex items-center my-0.5">
              <FaArrowDownShortWide size={25} color="white" />
              <p className="text-white font-bold text-lg ml-3">
                Compte ID : {productDetail.numero_compte}
              </p>
            </div>

            {/* Nombre de cotisation en cours */}
            <div className="flex items-center my-0.5">
              <RiMoneyEuroCircleLine size={25} color="white" />
              <p className="text-white font-bold text-lg ml-3">
                Cotisation en cours de progression :{" "}
                {productDetail.temp_progress} Fois
              </p>
            </div>

            {/* Montant de cotisation en cours */}
            <div className="flex items-center my-0.5">
              <RiProgress1Line size={25} color="white" />
              <p className="text-white font-bold text-lg ml-3">
                Montant en cours de progression :{" "}
                {(
                  parseFloat(productDetail.montant_en_cours).toFixed(0) * 1
                ).toLocaleString("fr-FR") + " FCFA"}
              </p>
            </div>

            {/* Commission */}
            <div className="flex items-center my-0.5">
              <BiMoney size={25} color="white" />
              <p className="text-white font-bold text-lg ml-3">
                Commission :{" "}
                {(
                  parseFloat(productDetail.commission).toFixed(0) * 1
                ).toLocaleString("fr-FR") + " F"}
              </p>
            </div>

            {/* Prix de vente */}
            <div className="flex items-center my-0.5">
              <MdOutlineSell size={25} color="white" />
              <p className="text-white font-bold text-lg ml-3">
                Prix de vente :{" "}
                {(
                  parseFloat(productDetail.prix_vente).toFixed(0) * 1
                ).toLocaleString("fr-FR") + " F"}
              </p>
            </div>

            {/* Validite */}
            <div className="flex items-center my-0.5">
              <IoTimeOutline size={25} color="white" />
              <p className="text-white font-bold text-lg ml-3">
                Validité : {productDetail.temp_expiration} Jours
              </p>
            </div>

            {/* Status */}
            <div className="flex items-center my-0.5">
              <TbStatusChange size={25} color="white" />
              <p className="text-white font-bold text-lg ml-3">
                Status :{" "}
                {productDetail.status === "Livré"
                  ? "Livré"
                  : productDetail.status === "En cours" && "En cours"}
              </p>
            </div>

            {/* Date */}
            <div className="flex items-center my-0.5">
              <IoCalendarNumberOutline size={25} color="white" />
              <p className="text-white font-bold text-lg ml-3">
                Date : {moment(productDetail.created_at).calendar()}
              </p>
            </div>
          </div>

          {/* Confirme Product delivery */}
          <div className="my-2 flex h-[8%] px-10 justify-center">
            {productDetail.status === "Livré" ? (
              <div className="flex items-center">
                <p className="text-red-500 text-center">Carnet déjà livré</p>
              </div>
            ) : (
              <button
                disabled={
                  productDetail.temp_en_cours < productDetail.temp_expiration &&
                  productDetail.status !== "Livré"
                    ? true
                    : false
                }
                className={
                  productDetail.temp_en_cours < productDetail.temp_expiration &&
                  productDetail.status !== "Livré"
                    ? "rounded-xl bg-gray-600 flex items-center justify-center hover:bg-gray-600 w-full"
                    : "rounded-xl bg-red-500 flex items-center justify-center hover:bg-red-700 w-full"
                }
                onClick={() => setModal3(true)}
              >
                <TbTruckDelivery size={25} color="white" />
                <p className="text-white text-lg ml-3">Livré ce carnet</p>
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Confirme delivery */}
      <div
        className={
          modal3
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-3/12 top-[20%] h-[22%] bg-cyan-900 shadow-xl rounded-xl p-2">
          {/* icon & Title */}
          <button
            onClick={() => setModal3(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              {/* Header */}
              <div className="p-2 rounded-full flex flex-col items-center justify-center">
                <FcCancel size={50} />
                <p className="text-white text-lg font-semibold text-center">
                  Confirmer la livraison de cette commande ?
                </p>
              </div>

              {/* Buttons */}
              <div className="flex mt-10 justify-between">
                <button
                  onClick={() => setModal3(false)}
                  className="bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Non
                </button>

                <button
                  onClick={() => confirmOrder()}
                  className="bg-red-600 w-[48%] hover:bg-red-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cancel order */}
      <div
        className={
          modal2
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-3/12 top-[20%] h-[22%] bg-cyan-900 shadow-xl rounded-xl p-2">
          {/* icon & Title */}
          <button
            onClick={() => setModal2(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              {/* Header */}
              <div className="p-2 rounded-full flex flex-col items-center justify-center">
                <FcCancel size={50} />
                <p className="text-white text-lg font-semibold text-center">
                  Anuler cette commande ?
                </p>
              </div>

              {/* Buttons */}
              <div className="flex mt-10 justify-between">
                <button
                  onClick={() => setModal2(false)}
                  className="bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Non
                </button>

                <button
                  // onClick={() => setModal6(false)}
                  className="bg-red-600 w-[48%] hover:bg-red-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Cotisation history */}
      <div
        className={
          modal4
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-5/12 top-[15%] h-[80%] bg-cyan-900 shadow-xl rounded-xl p-2">
          {/* icon & Title */}
          <button
            onClick={() => setModal4(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              {/* Header */}
              <div className="p-2 rounded-full flex flex-row items-center justify-center">
                <FcInfo size={25} />
                <p className="text-white text-lg font-semibold text-center ml-2">
                  Historique de cotisation
                </p>
              </div>

              {/* BOdy */}
              <div className="w-full h-[auto] overflow-auto scrollbar-hide">
                {carnetHistoryList.map((item, index) => (
                  <div
                    key={index}
                    className="bg-[#ffffff24] rounded-xl my-2 p-2"
                  >
                    <p className="text-blue-500 text-base">
                      ID : {item.numero_compte}
                    </p>
                    <p className="text-white font-bold text-lg text-base">
                      {item.product_name}
                    </p>
                    <p className="text-white text-base">
                      Montant :{" "}
                      {(
                        parseFloat(item.montant_cotisation).toFixed(0) * 1
                      ).toLocaleString() +
                        " " +
                        "F"}
                    </p>
                    <p className="text-white text-base">
                      {" "}
                      Nombre de colonnes : {item.number_of_cotisation} Fois
                    </p>
                    <p className="text-white text-base">
                      {" "}
                      Date : {moment(item.created_at).calendar()}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
