import React from "react";
import StockAgence from "../../components/stock_agence/AgenceListe"

export default function CarnetManager() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <StockAgence />
    </div>
  );
}
