import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import Config from "../../config/Links";
import { IoMdClose } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { MdInfoOutline } from "react-icons/md";
import { AiTwotonePlusCircle } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import { FiMinusCircle } from "react-icons/fi";
import { GoPlusCircle } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Load from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotCarnet from "../../assets/svg/no_carnet.png";
import { GoHistory } from "react-icons/go";
import qs from "qs";
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";

export default function AgenceListe() {
  const navigation = useNavigate();
  moment.locale("fr");
  const [agenceData, setAgenceData] = useState([]);
  const [carnetListe, setCarnetListe] = useState([]);
  const [getAgenceCarnetListe, setGetAgenceCarnetListe] = useState([]);
  const [agenceHistoryListe, setAgenceHistoryListe] = useState([]);
  const [deliveryHistoryListe, setDeleiveryHistoryListe] = useState([]);
  const [agenceName, setAgenceName] = useState("");
  const [agenceN, setAgenceN] = useState("");

  const [carnetSearch, setCarnetSearch] = useState("");
  const [carnetSelect, setCarnetSelect] = useState([]);

  // Modal State
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);

  // Loading State
  const [loading0, setLoading0] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  // NotFound State
  const [notFound0, setNotFound0] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [notFound2, setNotFound2] = useState(false);

  // Agence State
  const [agenceID, setAgenceID] = useState("");

  // Fetch All Agence Liste
  const fetchAgence = React.useCallback(async () => {
    const gesToken = sessionStorage.getItem("gestoken");
    try {
      const response = await axios.post(
        `${Config.GESCAPO_LINK}/api/auth/admin/agence/list?search=${agenceName}`,
        null,
        { headers: { Authorization: `Bearer ${gesToken}` } }
      );
      setAgenceData(response.data.data);
    } catch (error) {
      console.error("Error fetching agence data:", error);
    } finally {
      setLoading(false);
    }
  }, [agenceName, agenceID]);

  useEffect(() => {
    fetchAgence();
  }, [fetchAgence]);

  // Fetch All Carnets List
  const [carnetAvailable, setCarnetAvailable] = useState([]);

  useEffect(() => {
    const getAgenceListe = async () => {
      const token = sessionStorage.getItem("gestoken");
      try {
        const response = await axios.post(
          `${Config.GESCAPO_LINK}/api/auth/admin/carnet/list?search=${carnetSearch}&all=true`,
          null,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setCarnetListe(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getAgenceListe();
  }, [carnetSearch, agenceID]);

  // Get Agence Dleivery History
  useEffect(() => {
    getDleiveryHistory();
  }, [agenceID]);

  const getDleiveryHistory = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/get/${agenceID}/delivery/history`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setLoading2(false);
        setNotFound2(false);
        setDeleiveryHistoryListe(response.data.data);
        console.log("Delivery", response.data.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setLoading2(false);
          setNotFound2(true);
        }
        console.log(error);
      });
  };

  // Get Agence Tranasaction History
  useEffect(() => {
    if (agenceID) {
      getAgenceStockHistory();
    }
  }, [agenceID]);

  const getAgenceStockHistory = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/admin/get/stock/history/${agenceID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setAgenceHistoryListe(response.data.data);
        setLoading1(false);
        setNotFound(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setLoading1(false);
          setNotFound(true);
        }
        console.log(error);
      });
  };

  // Get Info
  useEffect(() => {
    if (agenceID) {
      getCarnetsInfo(agenceID);
    }
  }, [agenceID]);

  const getCarnetsInfo = async (index) => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/admin/get/agence/stock/info/${index}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.request(config);
      if (response.data && response.data.data) {
        setNotFound0(false);
        setLoading0(false);
        setGetAgenceCarnetListe(response.data.data);
      } else {
        console.warn("Données invalides reçues :", response.data);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setLoading0(false);
        setNotFound0(true);
      }
      console.error("Erreur lors de la récupération des carnets :", error);
    }
  };

  // Sckock Handler state
  const [stockID, setStockID] = useState("");
  const [stockQuantityPlus, setStockQuantityPlus] = useState(0);
  const [stockQuantityMinus, setStockQuantityMinus] = useState(0);

  // INcrement Carnet Number
  const incrementCarnetNumber = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      quantite: stockQuantityPlus,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/admin/increment/stock/${stockID}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          setModal5(false);
          window.location.reload();
        }, 1000);
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Decrement Carnet Number
  const decrementCarnetNumber = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      quantite: stockQuantityMinus,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v1/admin/decrement/stock/${stockID}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        console.log(JSON.stringify(response.data));
        setTimeout(() => {
          setModal4(false);
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.response.data);
        console.log(error);
      });
  };

  return (
    <>
      <div className="py-[60px] w-full flex-col  max-sm:px-2">
        {/* Header */}
        <div className="w-full mb-2 flex flex-row items-center justify-end px-3 max-sm:px-0">
          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recherche d'agence"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={agenceName}
              onChange={(e) => setAgenceName(e.target.value)}
            />
          </div>
        </div>

        {/* Body */}
        {loading ? (
          <div className="flex h-[80vh] flex-col items-center justify-center">
            <img src={Load} className="w-12 h-12" />
          </div>
        ) : (
          agenceData.length > 0 && (
            <div className="flex flex-col overflow-auto scrollbar-hide h-[90vh]">
              {[...agenceData]?.map((agence, index) => (
                <div
                  key={index}
                  className="w-full p-5 rounded-md flex flex-row items-center justify-between bg-[#ADADAD36] mb-2 max-sm:flex-col max-sm:items-start max-sm:gap-2 max-sm:p-2
          "
                >
                  {/* Left */}
                  <div>
                    <p className="text-white font-bold text-base">
                      {agence.nom_agence}
                    </p>

                    {/* Stock Activf */}
                    <p className="text-white">Stock Actif :</p>
                  </div>

                  {/* Rigth */}
                  <div className="flex items-start">
                    {/* Agence Delivery Info */}
                    <button
                      onClick={() => {
                        setModal3(true);
                        setAgenceID(agence.id);
                        setAgenceN(agence.nom_agence);
                        getAgenceStockHistory();
                      }}
                      className="bg-[#ffffff14] hover:bg-[#ffffff34] p-3 mr-3 rounded-xl flex items-center justify-center"
                    >
                      <TbTruckDelivery size={25} color="white" />
                    </button>

                    {/* Agence Info */}
                    <button
                      onClick={() => {
                        setModal2(true);
                        getCarnetsInfo(agence.id);
                        setAgenceN(agence.nom_agence);
                      }}
                      className="bg-[#ffffff14] hover:bg-[#ffffff34] p-3 mr-3 rounded-xl flex items-center justify-center"
                    >
                      <MdInfoOutline size={25} color="white" />
                    </button>

                    {/* History Button */}
                    <button
                      onClick={() => {
                        setModal1(true);
                        setAgenceID(agence.id);
                        setAgenceN(agence.nom_agence);
                      }}
                      className="bg-[#ffffff14] hover:bg-[#ffffff34] p-3 mr-3 rounded-xl flex items-center justify-center"
                    >
                      <GoHistory size={25} color="white" />
                    </button>

                    {/* Add Carnet Button */}
                    <button
                      onClick={() => {
                        navigation(
                          `/dashboard/agence/add/carnet/${agence.id}/${agence.nom_agence}`
                        );
                      }}
                      className="bg-blue-500 flex items-center p-3 rounded-xl shadow-md hover:bg-blue-800"
                    >
                      <AiTwotonePlusCircle size={25} color="white" />
                      <p className="text-white ml-2">Ajouter des carnets</p>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>

      {/* Get Agence delivery history */}
      <div
        className={
          modal3
            ? "w-[83.5%] h-[100vh] bg-[#38373780] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-8/12 top-[15%] h-[70%] bg-cyan-950 rounded-xl p-4">
          {/* Close */}
          <button
            onClick={() => setModal3(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body */}
          <div>
            {/* Title */}
            <div>
              <p className="text-white font-bold text-lg text-center">
                Livraisons {agenceN}
              </p>

              {/* History */}
              {loading2 ? (
                <div className="flex h-[60vh] flex-col items-center justify-center">
                  <img src={Load} className="w-12 h-12" />
                </div>
              ) : notFound2 ? (
                <div className="flex items-center justify-center">
                  <p className="text-white font-bold mt-5">
                    Historique des livraisons vide !
                  </p>
                </div>
              ) : (
                <div className="bg-[#ffffff14] flex flex-col overflow-auto scrollbar-hide h-[580px] mt-2 p-3 rounded-xl">
                  {/* Title */}
                  <div className="p-3 rounded-xl absolute top-2">
                    <p className="text-blue-500 font-bold text-lg text-base">
                      Historique des livraisons{" "}
                      {setDeleiveryHistoryListe.length}
                    </p>
                  </div>

                  {deliveryHistoryListe.map((history, index) => {
                    return (
                      <div
                        title={history.stock_name}
                        key={index}
                        className="flex justify-between bg-[#ffffff14] rounded-xl items-center my-2 p-3"
                      >
                        <div>
                          <p className="text-white text-base">
                            {history.stock_name.length > 50
                              ? history.stock_name.slice(0, 50) + "..."
                              : history.stock_name}
                          </p>

                          <p className="text-white text-sm">
                            Collecteur : {history.col_name}
                          </p>

                          <p className="text-white text-sm">
                            Client : {history.client_name}
                          </p>

                          <p className="text-white text-sm">
                            Date : {moment(history.created_at).calendar()}
                          </p>
                        </div>

                        {/* Right */}
                        <div>
                          <p className="text-red-500 font-bold text-sm">
                            Livrés : - {history.quantite_delivery}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Get History Modal */}
      <div
        className={
          modal1
            ? "w-[83.5%] h-[100vh] bg-[#38373780] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-8/12 top-[15%] h-[70%] bg-cyan-950 rounded-xl p-4">
          {/* Close */}
          <button
            onClick={() => setModal1(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body */}
          <div>
            {/* Title */}
            <div>
              <p className="text-white font-bold text-lg text-center">
                Modification stocks {agenceN}
              </p>
            </div>

            {/* History */}
            {loading1 ? (
              <div className="flex h-[80vh] flex-col items-center justify-center">
                <img src={Load} className="w-12 h-12" />
              </div>
            ) : notFound ? (
              <div className="flex items-center justify-center">
                <p className="text-white font-bold mt-5">Historique vide !</p>
              </div>
            ) : (
              <div className="bg-[#ffffff14] flex flex-col overflow-auto h-[580px] mt-2 p-3 rounded-xl">
                {/* Title */}
                <div className="p-3 rounded-xl absolute top-2">
                  <p className="text-blue-500 font-bold text-lg text-base">
                    Historique des transactions {agenceHistoryListe.length}
                  </p>
                </div>

                {agenceHistoryListe.map((history, index) => {
                  return (
                    <div
                      title={history.carnet_name}
                      key={index}
                      className="flex justify-between bg-[#ffffff14] rounded-xl items-center my-2 p-3"
                    >
                      <div>
                        <p className="text-white text-base">
                          {history.carnet_name.length > 50
                            ? history.carnet_name.slice(0, 50) + "..."
                            : history.carnet_name}
                        </p>

                        <p className="text-white text-sm">
                          Date : {moment(history.created_date).calendar()}
                        </p>
                      </div>

                      {/* Right */}
                      <div>
                        <p
                          className={
                            history.type === 0
                              ? "text-red-500 text-sm font-bold"
                              : "text-green-500 font-bold text-sm"
                          }
                        >
                          Action :{" "}
                          {history.type === 0
                            ? `- ${history.new_quantite}`
                            : `+ ${history.new_quantite}`}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Get Agence Info */}
      <div
        className={
          modal2
            ? "w-[83.5%] h-[100vh] bg-[#38373780] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-8/12 top-[15%] h-[70%] bg-cyan-950 rounded-xl p-4">
          {/* Close */}
          <button
            onClick={() => setModal2(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body */}
          <div>
            {/* Title */}
            <div>
              <p className="text-white font-bold text-lg text-center">
                {agenceN}
              </p>
            </div>

            {/* Carnets && Stock */}
            {loading0 ? (
              <div className="flex h-[auto] flex-col items-center justify-center">
                <img src={Load} className="w-12 h-12" />
              </div>
            ) : notFound0 ? (
              <div className="flex items-center justify-center">
                <p className="text-white font-bold mt-10">
                  {" "}
                  Listes de stock vide !
                </p>
              </div>
            ) : (
              <div className="bg-[#ffffff14] mt-2 p-3 mt-10 rounded-xl">
                <div className="flex w-11/12 top-4 flex-row absolute justify-between">
                  <p className="text-green-500 mr-3 font-medium text-xl">
                    Stock des carnets disponibles :{" "}
                    {getAgenceCarnetListe.length}
                  </p>

                  <p className="text-blue-500 ml-3 font-medium text-xl">
                    Qté stocks disponibles :{" "}
                    {getAgenceCarnetListe
                      .reduce((acc, cc) => acc + cc.stock, 0)
                      .toLocaleString("fr-FR")}
                  </p>
                </div>

                {/* Listes of stock */}
                <div className="flex flex-wrap w-full overflow-auto scrollbar-hide h-[600px]">
                  {getAgenceCarnetListe.map((carnet, index) => (
                    <div
                      title={carnet.carnet_name}
                      key={index}
                      className="flex flex-col w-[23%] h-[150px] justify-between bg-slate-900 m-2 rounded-xl hover:bg-blue-900 cursor-pointer p-3 items-start"
                    >
                      {/* Carnet Name */}
                      <p className="text-white text-lg text-sm mr-2">
                        {carnet.carnet_name.length > 25
                          ? carnet.carnet_name.slice(0, 25) + "..."
                          : carnet.carnet_name}
                      </p>

                      {/* Stock */}
                      <p
                        className={
                          carnet.stock > 5
                            ? "text-green-500 font-bold text-lg"
                            : "text-red-500 font-bold text-lg"
                        }
                      >
                        Stock : {carnet.stock}
                      </p>

                      {/* Update Date */}
                      <p className="text-white text-xs text-right">
                        Mise à jour : {moment(carnet.updatet_date).calendar()}
                      </p>

                      {/* Actions Button */}
                      <div className="flex w-full mt-2 items-center justify-between">
                        <button
                          onClick={() => {
                            setModal4(true);
                            setStockID(carnet.id);
                          }}
                          disabled={carnet.stock === 0}
                          className={
                            carnet.stock === 0
                              ? "bg-red-600 w-5/12 flex justify-center hover:bg-red-800 p-3 rounded-xl opacity-40"
                              : "bg-red-600 w-5/12 flex justify-center hover:bg-red-800 p-3 rounded-xl"
                          }
                        >
                          <FiMinusCircle size={20} color="white" />
                        </button>

                        <button
                          onClick={() => {
                            setModal5(true);
                            setStockID(carnet.id);
                          }}
                          className="bg-green-600 w-5/12 flex justify-center hover:bg-green-800 p-3 rounded-xl"
                        >
                          <GoPlusCircle size={20} color="white" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Diminution Modal */}
      <div className={modal4 ? "w-full h-full flex justify-center" : "hidden"}>
        <div className="absolute w-2/12 top-[20%] h-[18%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal4(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              <p className="text-white text-lg font-semibold text-center">
                Diminuer ce carnet ?
              </p>

              {/* input */}
              <div className="p-2 bg-white mt-3 rounded-xl">
                <input
                  className="w-full text-red-500 font-medium border-none outline-none"
                  maxLength={3}
                  placeholder="Nombre"
                  value={stockQuantityMinus}
                  onChange={(e) => {
                    const input = e.target.value;
                    const filteredInput = input.replace(/[^\d.]/g, "");
                    setStockQuantityMinus(filteredInput);
                  }}
                />
              </div>

              {/* Validate */}
              <div className="flex mt-8 flex-row justify-center">
                <button
                  onClick={() => decrementCarnetNumber()}
                  disabled={
                    stockQuantityMinus === 0 || stockQuantityMinus.length === 0
                  }
                  className={
                    stockQuantityMinus === 0 || stockQuantityMinus.length === 0
                      ? "bg-red-600 w-[48%] hover:bg-red-800 text-white p-3 rounded-xl flex items-center justify-center opacity-50"
                      : "bg-red-600 w-[48%] hover:bg-red-800 text-white p-3 rounded-xl flex items-center justify-center"
                  }
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Augmentation Modal */}
      <div className={modal5 ? "w-full h-full flex justify-center" : "hidden"}>
        <div className="absolute w-2/12 top-[20%] h-[18%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal5(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              <p className="text-white text-lg font-semibold text-center">
                Augmenter ce carnet ?
              </p>

              {/* input */}
              <div className="p-2 bg-white mt-3 rounded-xl">
                <input
                  className="w-full text-green-500 font-medium border-none outline-none"
                  maxLength={3}
                  placeholder="Nombre"
                  value={stockQuantityPlus}
                  onChange={(e) => {
                    const input = e.target.value;
                    const filteredInput = input.replace(/[^\d.]/g, "");
                    setStockQuantityPlus(filteredInput);
                  }}
                />
              </div>

              {/* Validate */}
              <div className="flex mt-8 flex-row justify-center">
                <button
                  onClick={() => incrementCarnetNumber()}
                  disabled={
                    stockQuantityPlus === 0 || stockQuantityPlus.length === 0
                  }
                  className={
                    stockQuantityPlus === 0 || stockQuantityPlus.length === 0
                      ? "bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center opacity-50"
                      : "bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                  }
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </>
  );
}
